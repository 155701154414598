import { useContext, useState } from 'react';
import { InsuranceContext } from '../../context/insurance';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';
import Step5 from './steps/Step5';
import BigImageLayout from 'components/common/BigImageLayout';

const InsuranceWrap = () => {
	const { state } = useContext(InsuranceContext);
	const [numberCity, setNumberCity] = useState('0');

	// Lógica para cambiar la imagen de fondo según la ciudad
	const getImageUrlForCity = city => {
		switch (city) {
			case '1':
				return '/images/Riviera%20Nayarit.jpg'; // Imagen para Riviera Nayarit
			case '2':
				return '/images/Los%20Cabos.jpg'; // Imagen para Los Cabos
			case '3':
				return '/images/Cozumel.jpg'; // Imagen para Cozumel
			case '4':
				return '/images/Cancun.jpg'; // Imagen para Cancún
			case '5':
				return '/images/Playa%20del%20Carmen.jpg'; // Imagen para Playa del Carmen
			case '6':
				return '/images/Tulum.jpg'; // Imagen para Tulum
			default:
				return '/images/step4.jpg'; // Imagen predeterminada
		}
	};

	return (
		<>
			{state.step === 1 && (
				<BigImageLayout url="/images/step1.jpg">
					<Step1 />
				</BigImageLayout>
			)}
			{state.step === 2 && (
				<BigImageLayout url="/images/step2.jpg">
					<Step2 />
				</BigImageLayout>
			)}
			{state.step === 3 && (
				<BigImageLayout url={getImageUrlForCity(numberCity)}>
					<Step3 setNumberCity={setNumberCity} />
				</BigImageLayout>
			)}
			{state.step === 4 && (
				<BigImageLayout url={getImageUrlForCity(state.city)}>
					<Step4 />
				</BigImageLayout>
			)}
			{state.step === 5 && (
				<BigImageLayout url="/images/login.jpg">
					<Step5 />
				</BigImageLayout>
			)}
		</>
	);
};

export default InsuranceWrap;
