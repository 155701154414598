import styled from 'styled-components';

export const BreadCrumbs = ({ userLevels, setSelectedUser, setRole }) => {
	// Procesar niveles dinámicamente
	const levels = Object.entries(userLevels)
		.filter(([key, value]) => {
			// Filtrar solo los nombres y roles no vacíos
			return key.startsWith('nameUser') && value;
		})
		.map(([key, name]) => {
			const levelIndex = key.match(/\d+/)[0]; // Obtener el número del nivel
			const roleKey = `roleLv${levelIndex}`;
			const role = userLevels[roleKey] || ''; // Obtener el rol asociado al nivel
			const idKey = `idLv${levelIndex}`;
			const id = userLevels[idKey] || ''; // Obtener el ID asociado al nivel

			let label = '';
			if (role === 'root') {
				label = 'ADMINISTRADOR';
			} else if (role === 'partner') {
				label = 'ALIADO';
			} else if (role === 'seller') {
				label = 'GUÍA';
			} else if (role === 'client') {
				label = 'MIEMBRO';
			}
			return { label, name, id };
		});

	const handleRole = (role, id) => {
		// Si es ADMINISTRADOR, eliminar selectedUser
		if (role === 'ADMINISTRADOR') {
			localStorage.removeItem('selectedUser');
			setSelectedUser('');
			setRole('');
		} else {
			// Para otros roles, establecer el ID en selectedUser
			localStorage.setItem('selectedUser', id);
			setSelectedUser(id);
		}
	};

	return (
		<Navigation>
			{levels.map((level, index) => (
				<WrapNav
					key={index}
					onClick={() => handleRole(level.label, level.id)}>
					<TopNav>
						<span style={{ fontWeight: 'bold' }}>{level.label}</span>
					</TopNav>
					<BottomNav>
						<span style={{ fontWeight: 'bold' }}>{level.name}</span>
					</BottomNav>
				</WrapNav>
			))}
		</Navigation>
	);
};

const Navigation = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1em;
	background: ${({ theme }) => theme.primaryColor};
	width: 100%;
`;

const WrapNav = styled.div`
	display: flex;
	align-items: start;
	flex-direction: column;
	width: 100%;
	cursor: pointer;
`;

const TopNav = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	background: #fff;
	color: ${({ theme }) => theme.primaryColor};
	font-size: 1rem;
	font-weight: bold;
`;

const BottomNav = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	background: ${({ theme }) => theme.primaryColor};
	color: #fff;
`;
