import { useDashboardData } from '../../hooks/useDashoardData';
import Goals from 'components/dashboards/goals';
import Grid from 'components/common/Grid';
import Skeleton from 'components/common/Skeleton';
import Map from 'components/dashboards/map';
import LinkCreator from 'components/dashboards/linkCreator';
import { useEffect, useState } from 'react';
import Content from 'components/common/Content-v2';
import { DashboardTable } from 'components/tables/dashboard';
import { BreadCrumbs } from 'components/common/BreadCrumbs';
const Dashboard = () => {
	const [refresh, setRefresh] = useState(null);
	const [role, setRole] = useState(localStorage.getItem('role') ?? '');
	const [extraParams, setExtraParams] = useState([{ name: null, value: null }]);
	const { isLoading, response } = useDashboardData(extraParams, refresh);
	const [selectedUser, setSelectedUser] = useState(localStorage.getItem('selectedUser') ?? '');

	useEffect(() => {
		console.log('holi0');
		if (role && !selectedUser) {
			setExtraParams([{ name: 'roleFilter', value: role }]);
			setRefresh(new Date().getTime());
		} else if (role && selectedUser) {
			setExtraParams([
				{ name: 'roleFilter', value: role },
				{ name: 'selectedUser', value: selectedUser },
			]);
			setRefresh(new Date().getTime());
		} else if (!role && selectedUser) {
			console.log('selectedUser', selectedUser);
			setExtraParams([{ name: 'selectedUser', value: selectedUser }]);
			setRefresh(new Date().getTime());
		} else if (!role && !selectedUser) {
			setExtraParams([{ name: null, value: null }]);
			setRefresh(new Date().getTime());
		}
	}, [role, selectedUser]);

	return (
		<>
			{isLoading && (
				<Grid columns="1">
					<Skeleton count={30} />
				</Grid>
			)}
			{!isLoading && (
				<>
					<BreadCrumbs
						userLevels={{
							roleLv1: response.roleLv1,
							nameUserLv1: response.nameUserLv1,
							roleLv2: response.roleLv2,
							nameUserLv2: response.nameUserLv2,
							idLv2: response.idLv2,
							roleLv3: response.roleLv3,
							nameUserLv3: response.nameUserLv3,
							idLv3: response.idLv3,
							roleLv4: response.roleLv4,
							nameUserLv4: response.nameUserLv4,
						}}
						setSelectedUser={setSelectedUser}
						setRole={setRole}
					/>
					<LinkCreator />
					<Goals
						{...response}
						role={role}
					/>
					<Map
						{...response}
						setRole={setRole}
						role={role}
					/>
					{!isLoading && response.section.length && (
						<Content separator="2rem">
							<DashboardTable
								users={response.section}
								setRole={setRole}
								setSelectedUser={setSelectedUser}
							/>
						</Content>
					)}
				</>
			)}
		</>
	);
};
export default Dashboard;
