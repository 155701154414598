import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Input from 'components/common/forms/Input';
import Select from 'components/common/forms/Select';
import Submit from 'components/common/forms/Submit';
import Grid from 'components/common/Grid';
import Password from 'components/common/forms/Password';
import { useIntl } from 'react-intl';
const EditForm = props => {
	let navigate = useNavigate();
	const { formatMessage } = useIntl();
	return (
		<Formik
			initialValues={{
				name: props.name,
				lastName: props.lastName,
				gender: props.gender,
				age: props.age,
				telephone: props.telephone,
				email: props.email,
				password: '',
				confirmPassword: '',
				sellerMembership: props.seller?.membership || '',
				acceptTerms: true,
				role: 'client',
				lenguaje: 'es',
			}}
			validationSchema={Yup.object({
				name: Yup.string().required(formatMessage({ id: 'message.RequiredField' })),
				lastName: Yup.string().required(formatMessage({ id: 'message.RequiredField' })),
				age: Yup.string()
					.matches(/^[0-9]+$/, formatMessage({ id: 'message.OnlyNumbersAreAllowed' }))
					.required(formatMessage({ id: 'message.RequiredField' })),
				gender: Yup.string().required(formatMessage({ id: 'message.RequiredField' })),
				telephone: Yup.string()
					.min(10, formatMessage({ id: 'message.Minimum10Characters' }))
					.max(10, formatMessage({ id: 'message.Maximum10Characters' }))
					.required(formatMessage({ id: 'message.RequiredField' })),
				email: Yup.string().email(formatMessage({ id: 'message.InvalidEmail' })).required(formatMessage({ id: 'message.RequiredField' })),
				password: Yup.string().min(6, formatMessage({ id: 'message.Minimum6Characters' })),
				confirmPassword: Yup.string().oneOf(
					[Yup.ref('password'), null],
					formatMessage({ id: 'message.ThePasswordsMustMatch' })
				),
			})}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(true);
				if (values.password === '') {
					delete values.password;
				}
				api
					.patch(`/user/${props.id}`, values)
					.then(res => {
						toast.success(formatMessage({ id: 'message.MemberEditedSuccessfully' }));
						navigate('/admin/members');
					})
					.catch(err => {
						if (err.response.data.message === 'El correo ya está registrado') {
							toast.error(formatMessage({ id: 'message.TheEmailIsAlreadyRegistered' }));
						} else if (err.response.data.message === 'El teléfono ya está registrado') {
							toast.error(formatMessage({ id: 'message.ThePhoneNumberIsAlreadyRegistered' }));
						} else {
							toast.error(formatMessage({ id: 'message.UnexpectedError' }));
						}
						setSubmitting(false);
					});
			}}>
			{formik => (
				<>
					<form onSubmit={formik.handleSubmit}>
						<Grid columns="2">
							<Input
								name="name"
								type="text"
								label={formatMessage({ id: 'forms.name' })}
								placeholder={formatMessage({ id: 'forms.nameLabel' })}
							/>
							<Input
								name="lastName"
								type="text"
								label={formatMessage({ id: 'forms.lastName' })}
								placeholder={formatMessage({ id: 'forms.lastNameLabel' })}
							/>
							<Select
								name="gender"
								label={formatMessage({ id: 'forms.gender' })}
								setFieldValue={formik.setFieldValue}
								options={[
									{ value: 'male', label: formatMessage({ id: 'label.male' }) },
									{ value: 'female', label: formatMessage({ id: 'label.female' }) },
									{ value: 'sin-especificar', label: formatMessage({ id: 'label.other' }) },
								]}
							/>
							<Input
								name="age"
								type="text"
								label={formatMessage({ id: 'forms.age' })}
								placeholder={formatMessage({ id: 'forms.ageLabel' })}
							/>
							<Input
								name="telephone"
								type="number"
								label={formatMessage({ id: 'forms.whatsapp' })}
								placeholder={formatMessage({ id: 'forms.whatsappLabel' })}
							/>
							<Input
								name="email"
								type="email"
								label={formatMessage({ id: 'forms.email' })}
								placeholder={formatMessage({ id: 'forms.emailLabel' })}
							/>
							<Password
								name="password"
								type="password"
								label={formatMessage({ id: 'forms.password' })}
								placeholder={formatMessage({ id: 'forms.passwordLabel' })}
							/>
							<Password
								name="confirmPassword"
								type="password"
								label={formatMessage({ id: 'forms.confirmPassword' })}
								placeholder={formatMessage({ id: 'forms.confirmPassword' })}
							/>
							<Input
								name="sellerMembership"
								type="text"
								label={formatMessage({ id: 'forms.wilmaGuide' })}
								placeholder={formatMessage({ id: 'forms.wilmaGuideLabel' })}
							/>
						</Grid>
						<Msg>{formatMessage({ id: 'forms.largeRevenueM3' })}</Msg>
						<Submit disabled={formik.isSubmitting}>{formatMessage({ id: 'commons.save' })}</Submit>
					</form>
				</>
			)}
		</Formik>
	);
};

const MyForm = styled.form`
	padding: 1em 0 2em 0;
	width: 100%;
`;
const Msg = styled.div`
	width: 100%;
	margin-top: 1em;
	text-align: right;
`;
export default EditForm;
