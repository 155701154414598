import { useContext, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Input from 'components/common/forms/Input';
import Submit from 'components/common/forms/Submit';
import Select from 'components/common/forms/Select';
import FetchSelect from 'components/common/forms/FetchSelect';
import Grid from 'components/common/Grid';
import Membership from 'components/common/forms/Membership';
import MembershipData from 'utils/MembershipData';
import userService from 'services/userService';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import api from 'services/api';
import { GlobalsContext } from '../../../context/globals';

const AddQuoteForm = props => {
	const language = useContext(GlobalsContext);
	const { formatMessage } = useIntl();
	const { role } = userService.getUser();
	let navigate = useNavigate();
	let user = userService.getUser();
	const discount = userService.getSellerDiscount();

	return (
		<Formik
			initialValues={{
				businessName: '',
				streetAndNumber: '',
				state: '',
				companyCity: '',
				colony: '',
				zipCode: '',
				rfc: '',

				businessLine: '',
				commercialName: '',
				numberOfEmployees: '',
				largeRevenue: '',

				membership: '',
				city: '',
				compensationLimit: '',
				discount: 0,
				policeName: '',
				montoFinanciamiento: '',
				gastosDeExpedicion: '',
				IVA: '',
				primaNeta: '',
				primaTotal: '',
				primaPrimerPago: '',
				primaPagosSucesivos: 0,
				quote: true,
				client: user._id,
				lenguaje: language.state.language,
				name: '',
				lastName: '',
				gender: '',
				age: '',
				telephone: '',
				email: '',
				seller: '',
			}}
			validationSchema={Yup.object({
				businessName: Yup.string().required(formatMessage({ id: 'errors.required' })),
				streetAndNumber: Yup.string().required(formatMessage({ id: 'errors.required' })),
				state: Yup.string().required(formatMessage({ id: 'errors.required' })),
				companyCity: Yup.string().required(formatMessage({ id: 'errors.required' })),
				colony: Yup.string().required(formatMessage({ id: 'errors.required' })),
				zipCode: Yup.string().required(formatMessage({ id: 'errors.required' })),
				rfc: Yup.string().required(formatMessage({ id: 'errors.required' })),
				businessLine: Yup.string().required(formatMessage({ id: 'errors.required' })),
				commercialName: Yup.string().when('businessLine', {
					is: value => value !== '12',
					then: Yup.string().required(formatMessage({ id: 'errors.required' })),
				}),
				numberOfEmployees: Yup.string().when('businessLine', {
					is: value => value !== '12',
					then: Yup.string().required(formatMessage({ id: 'errors.required' })),
				}),
				membership: Yup.string().required(formatMessage({ id: 'errors.required' })),
				city: Yup.string().required(formatMessage({ id: 'errors.required' })),
				compensationLimit: Yup.number()
					.min(100000, formatMessage({ id: 'errors.minimun' }))
					.max(7000000, formatMessage({ id: 'errors.maximum' })),
				telephone: Yup.string()
					.min(10, formatMessage({ id: 'message.Minimum10Characters' }))
					.max(10, formatMessage({ id: 'message.Maximum10Characters' }))
					.required(formatMessage({ id: 'message.RequiredField' })),
				email: Yup.string().email(formatMessage({ id: 'message.InvalidEmail' })).required(formatMessage({ id: 'message.RequiredField' })),
			})}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(true);
				api
					.post('/police/create', values)
					.then(res => {
						toast.success(formatMessage({ id: 'message.QuotationSaved' }));
						navigate('/admin/quotes');
					})
					.catch(err => {
						setSubmitting(false);
						if (err.response) {
							if (
								err.response.data.message ===
								'El correo proporcionado ya está registrado pero no como cliente'
							) {
								toast.error(formatMessage({ id: 'message.TheProvidedEmailIsAlreadyRegisteredButNotAsAcustomer' }));
							} else if (err.response.data.message === 'El teléfono ya está registrado') {
								toast.error(formatMessage({ id: 'message.ThePhoneNumberIsAlreadyRegistered' }));
							} else if (err.response.data.message === 'Guía incorrecto, contacta a Wilma') {
								toast.error(formatMessage({ id: 'message.IncorrectGuidePleaseContactWilma' }));
							}
						} else {
							toast.error('Lo sentimos, tuvimos un error');
						}
					});
			}}>
			{formik => (
				<>
					<form onSubmit={formik.handleSubmit}>
						{role !== 'seller' && (
							<Grid columns="1">
								<FetchSelect
									name="seller"
									setFieldValue={formik.setFieldValue}
									label={formatMessage({ id: 'forms.selectSeller' })}
									endpoint="/user/sellers"
									searchedValue="id"
									searchedLabel="name"
								/>
							</Grid>
						)}
						<Grid
							columns="2"
							margin=" 2rem 0 0 0">
							<Input
								name="name"
								type="text"
								label={formatMessage({ id: 'forms.name' })}
								placeholder={formatMessage({ id: 'forms.nameLabel' })}
							/>
							<Input
								name="lastName"
								type="text"
								label={formatMessage({ id: 'forms.lastName' })}
								placeholder={formatMessage({ id: 'forms.lastNameLabel' })}
							/>
						</Grid>
						<Grid
							columns="2"
							margin=" 2rem 0 0 0">
							<Select
								name="gender"
								label={formatMessage({ id: 'forms.gender' })}
								setFieldValue={formik.setFieldValue}
								options={[
									{ value: 'male', label: formatMessage({ id: 'label.male' }) },
									{ value: 'female', label: formatMessage({ id: 'label.female' }) },
									{ value: 'sin-especificar', label: formatMessage({ id: 'label.other' }) },
								]}
							/>
							<Input
								name="age"
								type="text"
								label={formatMessage({ id: 'forms.age' })}
								placeholder={formatMessage({ id: 'forms.ageLabel' })}
							/>
						</Grid>
						<Grid
							columns="2"
							margin=" 2rem 0 0 0">
							<Input
								name="telephone"
								type="number"
								label={formatMessage({ id: 'forms.whatsapp' })}
								placeholder={formatMessage({ id: 'forms.whatsappLabel' })}
							/>
							<Input
								name="email"
								type="email"
								label={formatMessage({ id: 'forms.email' })}
								placeholder={formatMessage({ id: 'forms.emailLabel' })}
							/>
						</Grid>

						<Grid
							columns="2"
							margin=" 2rem 0 0 0">
							<Input
								name="businessName"
								type="text"
								label={formatMessage({ id: 'forms.contractor' })}
								placeholder={formatMessage({ id: 'forms.contractorLabel' })}
							/>
							<Input
								name="streetAndNumber"
								type="text"
								label={formatMessage({ id: 'forms.streetAndNumber' })}
								placeholder={formatMessage({ id: 'forms.streetAndNumberLabel' })}
							/>
							<Select
								name="state"
								label={formatMessage({ id: 'forms.state' })}
								setFieldValue={formik.setFieldValue}
								options={[
									{ value: 'Aguascalientes', label: 'Aguascalientes' },
									{ value: 'Baja California', label: 'Baja California' },
									{ value: 'Baja California Sur', label: 'Baja California Sur' },
									{ value: 'Campeche', label: 'Campeche' },
									{ value: 'Chiapas', label: 'Chiapas' },
									{ value: 'Chihuahua', label: 'Chihuahua' },
									{ value: 'Coahuila', label: 'Coahuila' },
									{ value: 'Colima', label: 'Colima' },
									{ value: 'CDMX', label: 'CDMX' },
									{ value: 'Durango', label: 'Durango' },
									{ value: 'Guanajuato', label: 'Guanajuato' },
									{ value: 'Guerrero', label: 'Guerrero' },
									{ value: 'Hidalgo', label: 'Hidalgo' },
									{ value: 'Jalisco', label: 'Jalisco' },
									{ value: 'Estado de México', label: 'Estado de México' },
									{ value: 'Michoacán', label: 'Michoacán' },
									{ value: 'Morelos', label: 'Morelos' },
									{ value: 'Nayarit', label: 'Nayarit' },
									{ value: 'Nuevo León', label: 'Nuevo León' },
									{ value: 'Oaxaca', label: 'Oaxaca' },
									{ value: 'Puebla', label: 'Puebla' },
									{ value: 'Querétaro', label: 'Querétaro' },
									{ value: 'Quintana Roo', label: 'Quintana Roo' },
									{ value: 'San Luis Potosí', label: 'San Luis Potosí' },
									{ value: 'Sinaloa', label: 'Sinaloa' },
									{ value: 'Sonora', label: 'Sonora' },
									{ value: 'Tabasco', label: 'Tabasco' },
									{ value: 'Tamaulipas', label: 'Tamaulipas' },
									{ value: 'Tlaxcala', label: 'Tlaxcala' },
									{ value: 'Veracruz', label: 'Veracruz' },
									{ value: 'Yucatán', label: 'Yucatán' },
									{ value: 'Zacatecas', label: 'Zacatecas' },
								]}
							/>
							<Input
								name="companyCity"
								type="text"
								label={formatMessage({ id: 'forms.companyCity' })}
								placeholder={formatMessage({ id: 'forms.companyCityLabel' })}
							/>
							<Input
								name="colony"
								type="text"
								label={formatMessage({ id: 'forms.colony' })}
								placeholder={formatMessage({ id: 'forms.colonyLabel' })}
							/>
							<Input
								name="zipCode"
								type="text"
								label={formatMessage({ id: 'forms.zipCode' })}
								placeholder={formatMessage({ id: 'forms.zipCodeLabel' })}
							/>
							<Input
								name="rfc"
								type="text"
								toUpperCase={true}
								label={formatMessage({ id: 'forms.rfc' })}
								placeholder={formatMessage({ id: 'forms.rfcLabel' })}
							/>
						</Grid>

						<Grid
							columns="2"
							margin="2rem 0 0 0">
							<Select
								name="businessLine"
								label={formatMessage({ id: 'forms.businessLine' })}
								setFieldValue={formik.setFieldValue}
								options={[
									{ value: '1', label: formatMessage({ id: 'opt.businessLine.1' }) },
									{ value: '2', label: formatMessage({ id: 'opt.businessLine.2' }) },
									{ value: '3', label: formatMessage({ id: 'opt.businessLine.3' }) },
									{ value: '4', label: formatMessage({ id: 'opt.businessLine.4' }) },
									{ value: '5', label: formatMessage({ id: 'opt.businessLine.5' }) },
									{ value: '6', label: formatMessage({ id: 'opt.businessLine.6' }) },
									{ value: '7', label: formatMessage({ id: 'opt.businessLine.7' }) },
									{ value: '8', label: formatMessage({ id: 'opt.businessLine.8' }) },
									{ value: '9', label: formatMessage({ id: 'opt.businessLine.9' }) },
									{ value: '10', label: formatMessage({ id: 'opt.businessLine.10' }) },
									{ value: '12', label: formatMessage({ id: 'opt.businessLine.12' }) },
									{ value: '11', label: formatMessage({ id: 'opt.businessLine.11' }) },
								]}
							/>
							{formik.values.businessLine !== '12' && (
								<>
									<Input
										name="commercialName"
										type="text"
										label={formatMessage({ id: 'forms.commercialName' })}
										placeholder={formatMessage({ id: 'forms.commercialNameLabel' })}
									/>
									<Select
										name="numberOfEmployees"
										label={formatMessage({ id: 'forms.numberOfEmployees' })}
										setFieldValue={formik.setFieldValue}
										options={[
											{ value: '0 a 10', label: `0 ${formatMessage({ id: 'opt.to' })} 10` },
											{ value: '20 a 50', label: `20 ${formatMessage({ id: 'opt.to' })} 50` },
											{ value: '50 a 100', label: `50 ${formatMessage({ id: 'opt.to' })} 100` },
											{ value: 'Más de 100', label: formatMessage({ id: 'opt.moreThan100' }) },
										]}
									/>
								</>
							)}
						</Grid>

						<Grid
							columns="2"
							margin="2rem 0 0 0">
							<Select
								name="city"
								label={formatMessage({ id: 'forms.selectCity' })}
								setFieldValue={formik.setFieldValue}
								options={[
									{ value: '1', label: 'Riviera Nayarit - Puerto Vallarta' },
									{ value: '2', label: 'Los Cabos' },
									{ value: '3', label: 'Cozumel' },
									{ value: '4', label: 'Cancún' },
									{ value: '5', label: 'Playa del Carmen' },
									{ value: '6', label: 'Tulum' },
								]}
							/>
							<Membership
								name="membership"
								label={formatMessage({ id: 'forms.selectMembership' })}
								setFieldValue={formik.setFieldValue}
								options={[
									{ value: '1', label: `Sandy - $250,000 ${formatMessage({ id: 'commons.mxn' })}` },
									{
										value: '2',
										label: `Michelle - $500,000 ${formatMessage({ id: 'commons.mxn' })}`,
									},
									{
										value: '3',
										label: `Katrina - $650,000 ${formatMessage({ id: 'commons.mxn' })}`,
									},
									{
										value: '4',
										label: `Wilma - ${formatMessage({ id: 'opt.customized' })}`,
									},
								]}
							/>
							{formik.values.membership === '4' && (
								<Input
									type="number"
									name="compensationLimit"
									label={formatMessage({ id: 'forms.compensationLimit' })}
									placeholder={formatMessage({ id: 'forms.compensationLimit' })}
									value={formik.values.compensationLimit}
								/>
							)}
						</Grid>

						<Grid columns="1">
							<Limit>
								<MembershipData
									city={formik.values.city}
									membership={formik.values.membership}
									discount={discount}
									compensationLimit={formik.values.compensationLimit}
									setFieldValue={formik.setFieldValue}
									values={formik.values}
								/>
							</Limit>
						</Grid>

						{/* <pre>{JSON.stringify(formik.values, null, 4)}</pre>
						<pre>{JSON.stringify(formik.errors, null, 4)}</pre> */}
						<Submit disabled={formik.isSubmitting}>{formatMessage({ id: 'commons.save' })}</Submit>
					</form>
				</>
			)}
		</Formik>
	);
};

const LabelRadio = styled.div`
	margin: 10px 0 5px 0;
	padding-left: 5px;
`;
const ExtraData = styled.div`
	p {
		margin-bottom: 1em;
	}
`;
const Limit = styled.div`
	padding-left: 5px;
	span {
		font-weight: 700;
		font-size: 2em;
		color: ${({ theme }) => theme.primaryColor};
	}
`;
export default AddQuoteForm;
