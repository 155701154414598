import { useState } from 'react';
import api from '../services/api';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';


export const useCreateSubscription = id => {
	const { formatMessage } = useIntl();
	const [isLoading, setIsLoading] = useState(false);
	const createSubscription = async () => {
		setIsLoading(true);
		try {
			const link = await api.get(`mercado-pago/create-preference?policeId=${id}`);
			window.location.href = link.preference.init_point;
		} catch (error) {
			toast.error(formatMessage({ id: 'message.ErrorGeneratingPaymentLink' }));
		}
	};

	return { createSubscription, isLoading };
};
