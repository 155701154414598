import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Input from 'components/common/forms/Input';
import Submit from 'components/common/forms/Submit';
import Select from 'components/common/forms/Select';
import Grid from 'components/common/Grid';
import Password from 'components/common/forms/Password';
import userService from 'services/userService';
import { useIntl } from 'react-intl';
const AddGuideForm = props => {
	let navigate = useNavigate();
	const { formatMessage } = useIntl();
	const { role } = userService.getUser();
	return (
		<Formik
			initialValues={{
				name: '',
				lastName: '',
				telephone: '',
				email: '',
				password: '',
				confirmPassword: '',
				discount: '0',
				role: 'seller',
				createdByAdmin: true,
				lenguaje: 'es',
				partnerMembership: props.partner?.membership || '',
				capacity: '',
				netPremium: '',
				memberships: '',
			}}
			validationSchema={Yup.object({
				name: Yup.string().required(formatMessage({ id: 'message.RequiredField' })),
				lastName: Yup.string().required(formatMessage({ id: 'message.RequiredField' })),
				telephone: Yup.string()
					.min(10, formatMessage({ id: 'message.Minimum10Characters' }))
					.max(10, formatMessage({ id: 'message.Maximum10Characters' }))
					.required(formatMessage({ id: 'message.RequiredField' })),
				email: Yup.string().email(formatMessage({ id: 'message.InvalidEmail' })).required(formatMessage({ id: 'message.RequiredField' })),
				password: Yup.string().min(6, formatMessage({ id: 'message.Minimum6Characters' })).required(formatMessage({ id: 'message.RequiredField' })),
				confirmPassword: Yup.string()
					.oneOf([Yup.ref('password'), null], formatMessage({ id: 'message.ThePasswordsMustMatch' }))
					.required(formatMessage({ id: 'message.RequiredField' })),
				capacity: Yup.string()
					.matches(/^[0-9]+$/, formatMessage({ id: 'message.OnlyNumbersAreAllowed' }))
					.required(formatMessage({ id: 'message.RequiredField' })),
				netPremium: Yup.string()
					.matches(/^[0-9]+$/, formatMessage({ id: 'message.OnlyNumbersAreAllowed' }))
					.required(formatMessage({ id: 'message.RequiredField' })),
				memberships: Yup.string()
					.matches(/^[0-9]+$/, formatMessage({ id: 'message.OnlyNumbersAreAllowed' }))
					.required(formatMessage({ id: 'message.RequiredField' })),
			})}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(true);
				api
					.post('auth/register/user', values)
					.then(res => {
						toast.success(formatMessage({ id: 'message.GuideCreatedSuccessfully' }));
						navigate('/admin/guides');
					})
					.catch(err => {
						if (err.response.data.message === 'El correo ya está registrado') {
							toast.error(formatMessage({ id: 'message.TheEmailIsAlreadyRegistered' }));
						} else if (err.response.data.message === 'El teléfono ya está registrado') {
							toast.error('El teléfono ya está registrado');
						} else {
							toast.error(formatMessage({ id: 'message.UnexpectedError' }));
						}
						setSubmitting(false);
					});
			}}>
			{formik => (
				<>
					<MyForm onSubmit={formik.handleSubmit}>
						<Grid columns="2">
							<Input
								name="name"
								type="text"
								label={formatMessage({ id: 'forms.name' })}
								placeholder={formatMessage({ id: 'forms.nameLabel' })}
							/>
							<Input
								name="lastName"
								type="text"
								label={formatMessage({ id: 'forms.lastName' })}
								placeholder={formatMessage({ id: 'forms.lastNameLabel' })}
							/>
							<Input
								name="telephone"
								type="number"
								label={formatMessage({ id: 'forms.whatsapp' })}
								placeholder={formatMessage({ id: 'forms.whatsappLabel' })}
							/>
							<Input
								name="email"
								type="email"
								label={formatMessage({ id: 'forms.email' })}
								placeholder={formatMessage({ id: 'forms.emailLabel' })}
							/>
							<Password
								name="password"
								type="password"
								label={formatMessage({ id: 'forms.password' })}
								placeholder={formatMessage({ id: 'forms.passwordLabel' })}
							/>
							<Password
								name="confirmPassword"
								type="password"
								label={formatMessage({ id: 'forms.confirmPassword' })}
								placeholder={formatMessage({ id: 'forms.confirmPassword' })}
							/>
							<Select
								name="discount"
								label={formatMessage({ id: 'table.discount' })}
								setFieldValue={formik.setFieldValue}
								options={[
									{ value: '0', label: '0%' },
									{ value: '5', label: '5%' },
									{ value: '10', label: '10%' },
									{ value: '15', label: '15%' },
									{ value: '20', label: '20%' },
								]}
							/>
							{role == 'root' && (
								<Input
									name="partnerMembership"
									type="text"
									label={formatMessage({ id: 'forms.partner' })}
									placeholder={formatMessage({ id: 'forms.partnerLabel' })}
								/>
							)}
							<Input
								name="capacity"
								type="text"
								label={formatMessage({ id: 'forms.capacity' })}
								placeholder={formatMessage({ id: 'forms.capacityLabel' })}
							/>
							<Input
								name="netPremium"
								type="text"
								label={formatMessage({ id: 'forms.netPremium' })}
								placeholder={formatMessage({ id: 'forms.netPremiumlabel' })}
							/>
							<Input
								name="memberships"
								type="text"
								label={formatMessage({ id: 'forms.memberships' })}
								placeholder={formatMessage({ id: 'forms.membershipsLabel' })}
							/>
						</Grid>
						<Submit
							type="submit"
							margin="3em auto 0 auto "
							disabled={formik.isSubmitting}>
							{formatMessage({ id: 'forms.registerForm' })}
						</Submit>
					</MyForm>
				</>
			)}
		</Formik>
	);
};

const MyForm = styled.form`
	padding: 1em 0 2em 0;
	width: 100%;
`;

export default AddGuideForm;
