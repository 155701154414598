import { useState, useEffect, useCallback } from 'react';
import api from '../services/api';

export const useDashboardData = (extraParams = [{ name: null, value: null }], refresh = null) => {
	const [isLoading, setIsLoading] = useState(true);
	const [response, setResponse] = useState([]);

	const createUrl = () => {
		const urlWithParams = new URL(`${process.env.REACT_APP_API}/police/dashboard`);
		if (extraParams && extraParams.length > 0) {
			extraParams.map(param => {
				if (param.value) urlWithParams.searchParams.set(param.name, param.value);
			});
		}
		return urlWithParams;
	};

	const fetchData = useCallback(async () => {
		try {
			setIsLoading(true);
			const urlParams = createUrl();
			let response = await api.get(urlParams);
			setResponse(response);
			setIsLoading(false);
		} catch (err) {
			console.log(err);
		}
	}, [extraParams]);

	useEffect(() => {
		fetchData();
	}, [fetchData, refresh]);

	return {
		isLoading,
		response,
	};
};
