import StyledTable from 'components/common/StyledTable';
import moment from 'moment';
import Options from './options';
import { useIntl } from 'react-intl';

export const DashboardTable = props => {
	const { formatMessage } = useIntl();

	const { users, setRole, setSelectedUser } = props;

	const handleView = id => {
		setSelectedUser(id);
		localStorage.setItem('selectedUser', id);

		setRole('');
	};

	const columns = [
		{
			name: formatMessage({ id: 'table.name' }),
			selector: row => row.name,
			sortable: true,
			// celda con nomobre clickable
			cell: row => (
				<div style={{ cursor: 'pointer' }}>
					<p onClick={() => handleView(row.id)}>{row.name}</p>
				</div>
			),
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.quotes' }),
			selector: row => row.quotes,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.active.quotes' }),
			selector: row => row.activeQuotes,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.inactive.quotes' }),
			selector: row => row.inactiveQuotes,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.memberships' }),
			selector: row => row.membership,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.last.login' }),
			selector: row => moment(row.lastlogin).format('DD/MM/YYYY'),
			sortable: true,
			grow: 1,
			wrap: true,
		},
	];
	return (
		<>
			<StyledTable
				data={users}
				columns={columns}
				progressPending={false}
				pagination
				paginationServer
				paginationTotalRows={users.legth}
				onChangeRowsPerPage={() => {}}
				onChangePage={() => {}}
				noDataComponent={<div>No hay datos</div>}
				paginationPerPage={users.legth}
			/>
		</>
	);
};
