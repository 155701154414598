import MenuOptions from 'components/common/MenuOptions';
import { MenuItem } from '@szhsin/react-menu';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

const DashboardOptions = props => {
	const { id, role } = props;
	const { formatMessage } = useIntl();

	// cuando den ver guardar el id en el local storage
	const handleView = id => {
		localStorage.setItem('selectedUser', id);
		localStorage.setItem('role', role);

		window.location.reload();
	};

	return (
		<Wrap>
			<MenuOptions>
				<MenuItem>
					<MenuItemWrap onClick={e => handleView(id)}>
						<p>{formatMessage({ id: 'opt.select' })}</p>
						<i className="material-icons-outlined">visibility</i>
					</MenuItemWrap>
				</MenuItem>
			</MenuOptions>
		</Wrap>
	);
};

const Wrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;
const MenuItemWrap = styled.div`
	width: 100%;
	padding: 0.375rem 1.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	i {
		font-size: 0.9rem;
	}
`;
export default DashboardOptions;
