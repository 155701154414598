import { useDataTable } from '../../../hooks/useDataTable';
import StyledTable from '../../common/StyledTable';
import moment from 'moment';
import DownloadReport from '../../common/DownloadReport';
import { useIntl } from 'react-intl';
import userService from 'services/userService';
import styled from 'styled-components';

const UsersTable = () => {
	const userRole = userService.getRole();
	const { isLoading, response, totalDocs, perPage, handlePageChange, handlePerRowsChange } =
		useDataTable('police/search', [
			{ name: 'quote', value: 'true' },
			{ name: 'active', value: 'false' },
		]);
	const { formatMessage } = useIntl();
	console.log(process.env.REACT_APP_API); // Verifica que la URL es correcta

	// Manejar la reactivación de una póliza
	const handleReactivate = async policeID => {
		try {
			const res = await fetch(`${process.env.REACT_APP_API}/police/reactivePolice/${policeID}`, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
			});
			if (res.ok) {
				alert(formatMessage({ id: 'table.policyReactivated' })); // Mensaje de éxito
				window.location.reload();
			} else {
				alert(formatMessage({ id: 'table.policyReactivateError' })); // Mensaje de error
			}
		} catch (error) {
			console.error(error);
			alert(formatMessage({ id: 'table.policyReactivateError' })); // Error en la petición
		}
	};

	const columns = [
		{
			name: formatMessage({ id: 'table.folio' }),
			selector: row => row.quotation,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.company' }),
			selector: row => row.businessName,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.insuredAmount' }),
			selector: row =>
				`$${new Intl.NumberFormat('es-MX', { style: 'decimal', minimumFractionDigits: 2 }).format(row.montoFinanciamiento)}`,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.premiumAmount' }),
			selector: row =>
				`$${new Intl.NumberFormat('es-MX', { style: 'decimal', minimumFractionDigits: 2 }).format(row.primaTotal)}`,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.membershipType' }),
			selector: row => row.policeName,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.seller' }),
			selector: row =>
				`${row?.seller?.membership || '-'} - ${row?.seller?.name || '-'} ${row?.seller?.lastName || '-'}`,
			sortable: true,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.dateOfCreation' }),
			selector: row => moment(row.createdAt).format('DD/MM/YYYY'),
			sortable: true,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.accionsTitle' }),
			cell: row => (
				<MyButton
					variant="contained"
					color="primary"
					onClick={() => handleReactivate(row._id)}>
					{formatMessage({ id: 'table.reactive' })}
				</MyButton>
			),
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		},
	];

	return (
		<>
			<StyledTable
				data={response}
				columns={columns}
				progressPending={isLoading}
				pagination
				paginationServer
				paginationTotalRows={totalDocs}
				onChangeRowsPerPage={handlePerRowsChange}
				onChangePage={handlePageChange}
				noDataComponent={<div>No hay datos</div>}
				paginationPerPage={perPage}
			/>
			{userRole === 'root' && (
				<DownloadReport
					justify="flex-end"
					endpoint="/user/clients-csv"
					name={formatMessage({ id: 'commons.downloadAll' })}
				/>
			)}
		</>
	);
};

const MyButton = styled.button`
	line-height: 1.5;
	font-weight: 700;
	letter-spacing: 0.05rem;
	padding: 0.8rem 1.5rem; /* Ajusta el padding */
	background-color: ${props => props.theme.primaryColor};
	color: #fff;
	border-radius: 10px; /* Cambia de 50px a 10px */
	transition: all 0.2s ease-in;
	border: none;
	cursor: pointer;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Añade una sombra */
	min-width: 120px; /* Ancho mínimo para mantener forma */
	text-align: center; /* Centra el texto */
	@media screen and (max-width: 768px) {
		padding: 0.6rem 1rem;
	}
	&:hover {
		color: #fff;
		background-color: ${props => props.theme.primaryHoverColor};
		box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15); /* Sombra más grande al hacer hover */
	}
	&:disabled {
		opacity: 0.6;
		cursor: not-allowed;
		&:hover {
			background-color: ${props => props.theme.primaryColor};
			box-shadow: none;
		}
	}
`;

export default UsersTable;
