import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import api from '../../../services/api';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Input from '../../common/forms/Input';
import Submit from '../../common/forms/Submit';
import Select from '../../common/forms/Select';
import Grid from '../../common/Grid';
import Password from '../../common/forms/Password';
import userService from '../../../services/userService';
import { useIntl } from 'react-intl';
const AddMemberForm = props => {
	let navigate = useNavigate();
	const { formatMessage } = useIntl();
	let user = userService.getUser();
	return (
		<Formik
			initialValues={{
				name: '',
				lastName: '',
				gender: '',
				age: '',
				telephone: '',
				email: '',
				password: '',
				confirmPassword: '',
				seller: user.membership,
				acceptTerms: true,
				role: 'client',
				createdByAdmin: true,
				lenguaje: 'es',
			}}
			validationSchema={Yup.object({
				name: Yup.string().required(formatMessage({ id: 'message.RequiredField' })),
				lastName: Yup.string().required(formatMessage({ id: 'message.RequiredField' })),
				age: Yup.string()
					.matches(/^[0-9]+$/, formatMessage({ id: 'message.OnlyNumbersAreAllowed' }))
					.required(formatMessage({ id: 'message.RequiredField' })),
				gender: Yup.string().required(formatMessage({ id: 'message.RequiredField' })),
				telephone: Yup.string()
					.min(10, formatMessage({ id: 'message.Minimum10Characters' }))
					.max(10, formatMessage({ id: 'message.Maximum10Characters' }))
					.required(formatMessage({ id: 'message.RequiredField' })),
				email: Yup.string().email(formatMessage({ id: 'message.InvalidEmail' })).required(formatMessage({ id: 'message.RequiredField' })),
				password: Yup.string().min(6, formatMessage({ id: 'message.Minimum6Characters' })).required(formatMessage({ id: 'message.RequiredField' })),
				confirmPassword: Yup.string()
					.oneOf([Yup.ref('password'), null], formatMessage({ id: 'message.ThePasswordsMustMatch' }))
					.required(formatMessage({ id: 'message.RequiredField' })),
			})}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(true);
				api
					.post('auth/register/user', values)
					.then(res => {
						toast.success(formatMessage({ id: 'message.WilmaMemberCreatedSuccessfully' }));
						navigate('/admin/members');
					})
					.catch(err => {
						if (err.response.data.message === 'El correo ya está registrado') {
							toast.error(formatMessage({ id: 'message.TheEmailIsAlreadyRegistered' }));
						} else if (err.response.data.message === 'El teléfono ya está registrado') {
							toast.error(formatMessage({ id: 'message.ThePhoneNumberIsAlreadyRegistered' }));
						} else {
							toast.error(formatMessage({ id: 'message.UnexpectedError' }));
						}
						setSubmitting(false);
					});
			}}>
			{formik => (
				<>
					<form onSubmit={formik.handleSubmit}>
						<Grid columns="2">
							<Input
								name="name"
								type="text"
								label={formatMessage({ id: 'forms.name' })}
								placeholder={formatMessage({ id: 'forms.nameLabel' })}
							/>
							<Input
								name="lastName"
								type="text"
								label={formatMessage({ id: 'forms.lastName' })}
								placeholder={formatMessage({ id: 'forms.lastNameLabel' })}
							/>
							<Select
								name="gender"
								label={formatMessage({ id: 'forms.gender' })}
								setFieldValue={formik.setFieldValue}
								options={[
									{ value: 'male', label: formatMessage({ id: 'label.male' }) },
									{ value: 'female', label: formatMessage({ id: 'label.female' }) },
									{ value: 'sin-especificar', label: formatMessage({ id: 'label.other' }) },
								]}
							/>
							<Input
								name="age"
								type="text"
								label={formatMessage({ id: 'forms.age' })}
								placeholder={formatMessage({ id: 'forms.ageLabel' })}
							/>
							<Input
								name="telephone"
								type="number"
								label={formatMessage({ id: 'forms.whatsapp' })}
								placeholder={formatMessage({ id: 'forms.whatsappLabel' })}
							/>
							<Input
								name="email"
								type="email"
								label={formatMessage({ id: 'forms.email' })}
								placeholder={formatMessage({ id: 'forms.emailLabel' })}
							/>
							<Password
								name="password"
								type="password"
								label={formatMessage({ id: 'forms.password' })}
								placeholder={formatMessage({ id: 'forms.passwordLabel' })}
							/>
							<Password
								name="confirmPassword"
								type="password"
								label={formatMessage({ id: 'forms.confirmPassword' })}
								placeholder={formatMessage({ id: 'forms.confirmPassword' })}
							/>
							<Input
								name="seller"
								type="text"
								label={formatMessage({ id: 'forms.wilmaGuide' })}
								placeholder={formatMessage({ id: 'forms.wilmaGuideLabel' })}
							/>
						</Grid>
						<Submit disabled={formik.isSubmitting}>{formatMessage({ id: 'commons.save' })}</Submit>
					</form>
				</>
			)}
		</Formik>
	);
};

const MyForm = styled.form`
	padding: 1em 0 2em 0;
	width: 100%;
`;

export default AddMemberForm;
